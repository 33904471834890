exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-rs-tsx": () => import("./../../../src/pages/index.rs.tsx" /* webpackChunkName: "component---src-pages-index-rs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-zones-a-and-b-index-rs-tsx": () => import("./../../../src/pages/zones/a-and-b/index.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-index-rs-tsx" */),
  "component---src-pages-zones-a-and-b-index-tsx": () => import("./../../../src/pages/zones/a-and-b/index.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-index-tsx" */),
  "component---src-pages-zones-a-and-b-validity-1-day-rs-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/1-day.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-1-day-rs-tsx" */),
  "component---src-pages-zones-a-and-b-validity-1-day-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/1-day.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-1-day-tsx" */),
  "component---src-pages-zones-a-and-b-validity-30-days-rs-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/30-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-30-days-rs-tsx" */),
  "component---src-pages-zones-a-and-b-validity-30-days-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/30-days.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-30-days-tsx" */),
  "component---src-pages-zones-a-and-b-validity-7-days-rs-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/7-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-7-days-rs-tsx" */),
  "component---src-pages-zones-a-and-b-validity-7-days-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/7-days.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-7-days-tsx" */),
  "component---src-pages-zones-a-and-b-validity-90-minutes-rs-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/90-minutes.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-90-minutes-rs-tsx" */),
  "component---src-pages-zones-a-and-b-validity-90-minutes-tsx": () => import("./../../../src/pages/zones/a-and-b/validity/90-minutes.tsx" /* webpackChunkName: "component---src-pages-zones-a-and-b-validity-90-minutes-tsx" */),
  "component---src-pages-zones-a-index-rs-tsx": () => import("./../../../src/pages/zones/a/index.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-index-rs-tsx" */),
  "component---src-pages-zones-a-index-tsx": () => import("./../../../src/pages/zones/a/index.tsx" /* webpackChunkName: "component---src-pages-zones-a-index-tsx" */),
  "component---src-pages-zones-a-validity-1-day-rs-tsx": () => import("./../../../src/pages/zones/a/validity/1-day.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-1-day-rs-tsx" */),
  "component---src-pages-zones-a-validity-1-day-tsx": () => import("./../../../src/pages/zones/a/validity/1-day.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-1-day-tsx" */),
  "component---src-pages-zones-a-validity-30-days-rs-tsx": () => import("./../../../src/pages/zones/a/validity/30-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-30-days-rs-tsx" */),
  "component---src-pages-zones-a-validity-30-days-tsx": () => import("./../../../src/pages/zones/a/validity/30-days.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-30-days-tsx" */),
  "component---src-pages-zones-a-validity-7-days-rs-tsx": () => import("./../../../src/pages/zones/a/validity/7-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-7-days-rs-tsx" */),
  "component---src-pages-zones-a-validity-7-days-tsx": () => import("./../../../src/pages/zones/a/validity/7-days.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-7-days-tsx" */),
  "component---src-pages-zones-a-validity-90-minutes-rs-tsx": () => import("./../../../src/pages/zones/a/validity/90-minutes.rs.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-90-minutes-rs-tsx" */),
  "component---src-pages-zones-a-validity-90-minutes-tsx": () => import("./../../../src/pages/zones/a/validity/90-minutes.tsx" /* webpackChunkName: "component---src-pages-zones-a-validity-90-minutes-tsx" */),
  "component---src-pages-zones-b-index-rs-tsx": () => import("./../../../src/pages/zones/b/index.rs.tsx" /* webpackChunkName: "component---src-pages-zones-b-index-rs-tsx" */),
  "component---src-pages-zones-b-index-tsx": () => import("./../../../src/pages/zones/b/index.tsx" /* webpackChunkName: "component---src-pages-zones-b-index-tsx" */),
  "component---src-pages-zones-b-validity-1-day-rs-tsx": () => import("./../../../src/pages/zones/b/validity/1-day.rs.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-1-day-rs-tsx" */),
  "component---src-pages-zones-b-validity-1-day-tsx": () => import("./../../../src/pages/zones/b/validity/1-day.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-1-day-tsx" */),
  "component---src-pages-zones-b-validity-30-days-rs-tsx": () => import("./../../../src/pages/zones/b/validity/30-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-30-days-rs-tsx" */),
  "component---src-pages-zones-b-validity-30-days-tsx": () => import("./../../../src/pages/zones/b/validity/30-days.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-30-days-tsx" */),
  "component---src-pages-zones-b-validity-7-days-rs-tsx": () => import("./../../../src/pages/zones/b/validity/7-days.rs.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-7-days-rs-tsx" */),
  "component---src-pages-zones-b-validity-7-days-tsx": () => import("./../../../src/pages/zones/b/validity/7-days.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-7-days-tsx" */),
  "component---src-pages-zones-b-validity-90-minutes-rs-tsx": () => import("./../../../src/pages/zones/b/validity/90-minutes.rs.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-90-minutes-rs-tsx" */),
  "component---src-pages-zones-b-validity-90-minutes-tsx": () => import("./../../../src/pages/zones/b/validity/90-minutes.tsx" /* webpackChunkName: "component---src-pages-zones-b-validity-90-minutes-tsx" */)
}

